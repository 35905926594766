// CMP
@import "core";

@include respond-to (base-inline) {

  // ------------------------------------------------------
  // Hide things (as suggested by OT team)
  #ot-sdk-btn-floating,
  #onetrust-close-btn-container,
  #onetrust-pc-sdk.ot-close-btn-link #close-pc-btn-handler,
  #onetrust-pc-sdk .ot-pc-logo,
  #onetrust-pc-sdk .ot-pc-footer-logo,
  #onetrust-pc-sdk .ot-pc-header,
  #onetrust-pc-sdk .ot-plus-minus,
  #onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) #ot-sel-blk,
  #onetrust-pc-sdk .ot-fltr-cntr,
  #onetrust-pc-sdk .ot-scrn-rdr,
  #onetrust-pc-sdk .ot-search-cntr>svg {
    display: none !important;
    visibility: hidden !important;
  }
}

@include respond-to (base) {
  div#onetrust-consent-sdk {

    // ------------------------------------------------------
    // reset silly width, padding and margin
    #onetrust-banner-sdk #onetrust-policy-title,
    #onetrust-banner-sdk #onetrust-policy-text,
    #onetrust-banner-sdk .ot-b-addl-desc,
    #onetrust-banner-sdk .ot-gv-list-handler,
    #onetrust-banner-sdk .ot-dpd-container,
    #onetrust-pc-sdk #ot-pc-content,
    #onetrust-banner-sdk #onetrust-policy {
      margin: 0;
      padding: 0;
      width: auto;
    }

    #onetrust-banner-sdk .ot-dpd-container {
      padding-top: var(--spacing-20);
    }

    // ------------------------------------------------------
    // Font and font size
    * {
      font-family: var(--font-family);
      font-size: var(--font-18);
      letter-spacing: 0;
    }

    // Heading
    #onetrust-banner-sdk #onetrust-policy-title,
    #onetrust-pc-sdk #ot-pc-title {
      font-size: var(--font-24-28);
      line-height: var(--line-110);
      font-weight: var(--font-bold);
      margin-bottom: var(--spacing-20);
      padding-bottom: 0;
      float: none;
    }

    // Paragraph
    #onetrust-policy-text,
    #onetrust-banner-sdk .ot-dpd-desc,
    #onetrust-pc-sdk #ot-pc-desc {
      font-size: var(--font-16);
      line-height: var(--line-140);
      margin-bottom: var(--spacing-20);
      padding-bottom: 0;
      font-weight: var(--font-regular);
    }

    // ------------------------------------------------------
    // Focus
    #onetrust-banner-sdk:focus,
    #onetrust-banner-sdk *:focus,
    #onetrust-pc-sdk *:focus,
    #onetrust-pc-sdk .ot-vlst-cntr>a:focus,
    #onetrust-pc-sdk .ot-tgl input:focus+.ot-switch,
    .ot-switch .ot-switch-nob,
    .ot-switch .ot-switch-nob::before,
    #onetrust-pc-sdk .ot-checkbox input[type="checkbox"]:focus+label::before,
    #onetrust-pc-sdk .ot-chkbox input[type="checkbox"]:focus+label::before {
      outline: none;
    }

    #onetrust-banner-sdk *:focus-visible,
    #onetrust-pc-sdk *:focus-visible,
    #onetrust-pc-sdk .ot-vlst-cntr>a:focus-visible,
    #onetrust-pc-sdk .ot-tgl input:focus-visible+.ot-switch,
    #onetrust-pc-sdk .ot-checkbox input[type="checkbox"]:focus-visible+label::before,
    #onetrust-pc-sdk .ot-chkbox input[type="checkbox"]:focus-visible+label::before {
      outline: var(--spacing-2) solid var(--color-focus);
      outline-offset: var(--spacing-2);
    }

    // ------------------------------------------------------------
    // Colors

    // links
    #onetrust-banner-sdk a[href],
    #onetrust-banner-sdk a[href] font,
    #onetrust-banner-sdk .ot-link-btn,
    #onetrust-pc-sdk a,
    #onetrust-pc-sdk .ot-link-btn{
      color: var(--color-link)!important;
      text-decoration: underline!important;
      font-weight: var(--font-regular);

      &:hover {
        color: var(--color-link-hover)!important;
      }
    }
    #onetrust-pc-sdk #ot-host-lst .ot-host-info{background-color:var(--color-100);}
    #onetrust-pc-sdk,
    #ot-search-cntr,
    #onetrust-pc-sdk .ot-switch.ot-toggle,
    #onetrust-pc-sdk ot-grp-hdr1 .checkbox,
    #onetrust-pc-sdk #ot-pc-title::after,
    #onetrust-pc-sdk #ot-sel-blk,
    #onetrust-pc-sdk #ot-fltr-cnt,
    #onetrust-pc-sdk #ot-anchor{
      background-color:var(--color-light);
    }

    #onetrust-pc-sdk .privacy-notice-link,
    #onetrust-pc-sdk .ot-pgph-link,
    #onetrust-pc-sdk .category-vendors-list-handler,
    #onetrust-pc-sdk .category-vendors-list-handler + a,
    #onetrust-pc-sdk .category-host-list-handler,
    #onetrust-pc-sdk .ot-ven-link,
    #onetrust-pc-sdk .ot-ven-legclaim-link,
    #onetrust-pc-sdk #ot-host-lst .ot-host-name a,
    #onetrust-pc-sdk #ot-host-lst .ot-acc-hdr .ot-host-expand,
    #onetrust-pc-sdk #ot-host-lst .ot-host-info a,
    #onetrust-pc-sdk #ot-pc-content #ot-pc-desc .ot-link-btn,
    #onetrust-pc-sdk .ot-vnd-serv .ot-vnd-item .ot-vnd-info a,
    #onetrust-pc-sdk #ot-lst-cnt .ot-vnd-info a{
      color:var(--color-link);
    }

    // text
    #onetrust-policy-title,
    #onetrust-policy-text,
    .ot-b-addl-desc,
    .ot-dpd-desc,
    .ot-dpd-title,
    #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
    .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
    #onetrust-banner-sdk #banner-options *,
    #onetrust-banner-sdk .ot-cat-header,
    #onetrust-pc-sdk h3,
    #onetrust-pc-sdk h4,
    #onetrust-pc-sdk h5,
    #onetrust-pc-sdk h6,
    #onetrust-pc-sdk p,
    #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p,
    #onetrust-pc-sdk #ot-pc-desc,
    #onetrust-pc-sdk #ot-pc-title,
    #onetrust-pc-sdk .ot-li-title,
    #onetrust-pc-sdk .ot-sel-all-hdr span,
    #onetrust-pc-sdk #ot-host-lst .ot-host-info,
    #onetrust-pc-sdk #ot-fltr-modal #modal-header,
    #onetrust-pc-sdk .ot-checkbox label span,
    #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p,
    #onetrust-pc-sdk #ot-pc-lst #ot-lst-title h3,
    #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p,
    #onetrust-pc-sdk #ot-pc-lst .ot-ven-name,
    #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category,
    #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn,
    #onetrust-pc-sdk .ot-label-status,
    #onetrust-pc-sdk .ot-chkbox label span,
    #onetrust-pc-sdk #clear-filters-handler {
      color: var(--color-text);
    }
    input{color:var(--color-midnight);}

    // ------------------
    // Button
    #onetrust-banner-sdk #onetrust-accept-btn-handler,
    #onetrust-banner-sdk #onetrust-reject-all-handler,
    #onetrust-banner-sdk #onetrust-pc-btn-handler,
    #onetrust-pc-sdk button:not(#onetrust-pc-sdk .ot-cat-item button):not(#onetrust-pc-sdk .ot-acc-cntr>button):not(.ot-ven-box),
    #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn{
      position: relative;
      letter-spacing: 0;
      padding: 0 var(--spacing-16-step);
      font-family: var(--font-family);
      font-size: var(--font-14-16);
      font-weight: var(--font-medium);
      box-sizing: border-box;
      height: var(--spacing-40);
      line-height: var(--spacing-40);
      border: none;
      border-radius: calc(var(--spacing-40) / 2);
      margin: 0;
      text-decoration: none;
      white-space: nowrap;
      text-align: center;
      width: auto;
      min-width: 0;
      max-width: 100%;
      cursor: pointer;
      transition: color var(--transition-normal), background-color var(--transition-normal);

      &.ot-host-box {
        height: 100%;
        position: absolute;
        width: 100%;
        outline: none;
      }

      &[disabled] {
        cursor: default;
      }
    }

    %button-primary {
      background-color: var(--color-dark);
      color: var(--color-light);

      &:hover {
        background-color: var(--color-800);
        opacity: 1;
      }
    }

    %button-secondary {
      background-color: var(--color-dark-200);
      color: var(--color-text);

      &:hover {
        background-color: var(--color-100);
        opacity: 1;
      }
    }

    #onetrust-pc-sdk #ot-lst-cnt {
      margin-left: 0;
    }

    .ot-sdk-row {
      margin-top: var(--spacing-32);
    }

    #ot-sel-blk {
      padding: var(--spacing-16) 0 0 0;
      height: var(--spacing-32);
    }

    #ot-host-lst .ot-host-item {
      margin: 0 0 var(--spacing-16) 0;
    }

    // List Item
    .ot-host-item {
      display: flex;
      flex-direction: column;
      border-radius: calc(var(--spacing-20) / 3);
      border: var(--spacing-1) solid #e2e2e2;
      margin: auto;
      padding: var(--spacing-12) 0;

      div#ot-host-acc-txt-0, .ot-acc-txt {
        align-self: center;
      }
    }

    #onetrust-accept-btn-handler,
    #onetrust-banner-sdk #onetrust-reject-all-handler,
    #onetrust-pc-sdk .ot-leg-btn-container .ot-active-leg-btn {
      @extend %button-primary;
    }

    #onetrust-pc-btn-handler,
    #onetrust-pc-btn-handler.cookie-setting-link,
    #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn {
      @extend %button-secondary;
    }

    // Reset crazy button selector
    #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn) {

      // Primary buttons
      &#accept-recommended-btn-handler {
        @extend %button-secondary;
      }

      // Secondary buttons
      &.ot-pc-refuse-all-handler,
      &.save-preference-btn-handler.onetrust-close-btn-handler {
        @extend %button-primary;
      }
    }

    // Toggle
    #onetrust-pc-sdk .ot-switch {
      height: var(--spacing-32);
      width: calc(var(--unit) * 51);
    }

    #onetrust-pc-sdk .ot-switch-nob {
      border: none;
      background-color: var(--color-300);

      &::before {
        height: calc(var(--unit) * 28);
        width: calc(var(--unit) * 28);
        bottom: auto;
        top: var(--spacing-2);
        left: var(--spacing-2);
        background-color: var(--color-light);
        transition: transform var(--transition-normal);
        box-shadow: 0 0 var(--spacing-3) rgba(0, 0, 0, 0.4);
      }
    }

    #onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
      border: none;
      background-color: var(--color-interactive-green-light);

      &::before {
        transform: translateX(calc(var(--unit) * 19));
        background-color: var(--color-light);
      }
    }

    #onetrust-pc-sdk li.ot-subgrp>h5+.ot-tgl-cntr {
      position: relative;
      top: calc(var(--spacing-8) * -1);
    }

    // Checkbox
    #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox,
    #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox label {
      height: var(--spacing-24);
      width: var(--spacing-24);
    }

    #onetrust-pc-sdk .ot-chkbox input:checked~label::before,
    #onetrust-pc-sdk .ot-chkbox label::before {
      background-color: var(--color-light);
      height: var(--spacing-24);
      width: var(--spacing-24);
      box-sizing: border-box;
      border-radius: var(--spacing-4);
      border: var(--spacing-1) solid;
    }

    #onetrust-pc-sdk .ot-chkbox input:checked~label::before,
    #onetrust-pc-sdk .ot-chkbox input:hover~label::before {
      border-color: var(--color-700);
      transition: border-color .0s;
    }

    #onetrust-pc-sdk .ot-chkbox label::before {
      border-color: var(--color-400);
      transition: border-color var(--transition-normal);
    }


    #onetrust-pc-sdk .ot-chkbox label::after {
      height: var(--spacing-24);
      width: var(--spacing-24);
      transform: none;
      border: none;
      mask-image: url("../../../../icons/check.svg");
      mask-size: var(--spacing-16);
      mask-repeat: no-repeat;
      mask-position: center;
      background-color:var(--color-dark);
      top: 0;
      left: 0;
    }

    #onetrust-pc-sdk .ot-chkbox input[type=checkbox]+label::after {
      color: var(--color-text);
    }

    // ------------------
    // Banner (screen 1)
    #onetrust-banner-sdk {
      width: 100%;
      max-width: none;
      height: 100%;
      max-height: 100dvh;
      position: fixed;
      box-shadow: none;
      background-color: transparent;
      top: 0;
      transform: none;

      .ot-tcf2-vendor-count.ot-text-bold {
        font-weight: bold;
      }
    }

    #onetrust-banner-sdk .ot-sdk-container {
      width: 100%;
      max-width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      transform: none;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 0;
    }

    #onetrust-banner-sdk .ot-sdk-row {
      width: var(--bp);
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--color-light);
    }

    #onetrust-banner-sdk.ot-close-btn-link #onetrust-group-container {
      margin-top: 0;
      overscroll-behavior: contain;
    }

    // Main
    #onetrust-banner-sdk #onetrust-group-container {
      padding-left: var(--gutter);
      padding-right: var(--gutter);
      padding-bottom: var(--spacing-12);
      overflow-y: auto;
      margin-top: 0;
    }

    // Logo
    #onetrust-banner-sdk .banner_logo {
      display: inline-block;
      background-image: url("../../../../icons/logo.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: var(--spacing-32-step);
      height: var(--spacing-32-step);
      margin-top: var(--spacing-32-step);
      margin-bottom: var(--spacing-16);
    }

    // Footer
    #onetrust-banner-sdk #onetrust-button-group-parent {
      min-height: unset;
      padding-top: var(--spacing-24-step);
      padding-bottom: var(--spacing-32-step);
      position: relative;
    }

    #onetrust-banner-sdk #onetrust-button-group {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: center;
      gap: var(--spacing-16);
    }

    .banner-actions-container {
      display: contents;
    }

    #onetrust-banner-sdk #onetrust-reject-all-handler {
      order: 1;
    }

    #onetrust-banner-sdk .has-reject-all-button #onetrust-pc-btn-handler {
      float: none;
      order: 2;
    }

    #onetrust-banner-sdk #onetrust-accept-btn-handler {
      order: 3;
    }

    // ------------------
    // CPC (screen 2)

    // Modify layout
    #onetrust-pc-sdk {
      height: 100% !important;
      position: fixed;
      top: 0;
      width: var(--bp);
      min-width: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      background-color: var(--color-light);
      box-shadow: none;
    }

    @supports (height:1dvh){
      #onetrust-pc-sdk {
        height: 100dvh !important;
        max-height: 100dvh;
      }
    }

    #onetrust-pc-sdk div[role="alertdialog"],#onetrust-pc-sdk div[role="dialog"] {
      display: contents;
    }

    // Header
    #onetrust-pc-sdk #ot-pc-title {
      &::before {
        display: block;
        content: '';
        background-image: url("../../../../icons/logo.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: var(--spacing-32-step);
        height: var(--spacing-32-step);
        margin-top: var(--spacing-32-step);
        margin-bottom: var(--spacing-16);
      }
    }

    // Content
    #onetrust-pc-sdk #ot-pc-content {
      position: relative;
      overflow-y: auto;
      top: 0;
      bottom: 0;
      padding-left: var(--gutter);
      padding-right: var(--gutter);
    }

    // Settings
    #onetrust-pc-sdk .ot-cat-grp {
      margin-top: var(--spacing-32-step);
      margin-bottom: var(--spacing-32-step);
      box-sizing: border-box;
      padding: 0;
    }

    #onetrust-pc-sdk #ot-category-title {
      font-weight: var(--font-medium);
      font-size: var(--font-14);
    }

    #onetrust-pc-sdk .ot-accordion-layout.ot-cat-item {
      border: none;
      background-color: var(--color-100);
      margin-bottom: var(--spacing-4);
      width: 100%;
      box-sizing: border-box;
    }

    #onetrust-pc-sdk .ot-accordion-layout.ot-cat-item:first-of-type {
      border: none;
      margin-top: 0;
    }

    #onetrust-pc-sdk .ot-cat-grp .ot-accordion-layout.ot-cat-item .ot-always-active {
      color: var(--color-text);
      opacity: .5;
      margin-right: 0;
      margin-left: 0;
      font-size: var(--font-12);
      font-weight: var(--font-regular);
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-acc-hdr {
      padding: var(--spacing-16);
      box-sizing: border-box;

      &::after {
        content: '';
        display: block;
        height: var(--spacing-20);
        width: var(--spacing-20);
        mask-image: url("../../../../icons/chevron-down.svg");
        mask-position: center;
        mask-size: var(--spacing-20);
        mask-repeat: no-repeat;
        background-color:var(--color-dark);
        margin-left: var(--spacing-16);
      }
    }

    #onetrust-pc-sdk .ot-cat-item>button[aria-expanded=true]~.ot-acc-hdr::after {
      transform: rotate(180deg);
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-cat-header,
    #onetrust-pc-sdk .ot-vs-config .ot-acc-hdr .ot-cat-header {
      font-size: var(--font-14);
      font-weight: var(--font-bold);
      margin-right: var(--spacing-16);
      margin-left: 0;
      min-height: auto;
    }

    #onetrust-pc-sdk .ot-vs-config .ot-acc-hdr .ot-tgl {
      margin: calc(var(--unit) * -2) 0;
    }

    #onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt {
      background-color: transparent;
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-acc-grpdesc {
      margin: 0 0 var(--spacing-20);
      padding-left: var(--spacing-16);
      padding-right: var(--spacing-16);
      font-size: var(--font-14);
      line-height: var(--line-130);
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-subgrp-cntr,
    #onetrust-pc-sdk .ot-accordion-layout .ot-subgrp {
      border: none;
      padding: 0;
      width: auto;
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-subgrp {
      margin-left: 0;
      padding-left: var(--spacing-16);
      padding-right: var(--spacing-48);
      margin-top: var(--spacing-16);
      margin-bottom: var(--spacing-16);
      overflow: visible;
    }

    #onetrust-pc-sdk ul.ot-subgrps li p,
    #onetrust-pc-sdk .ot-cat-item p {
      font-size: var(--font-14);
      line-height: var(--line-130);
      margin-bottom: var(--spacing-20);
      margin-top: 0;
      padding-bottom: 0;
      font-weight: var(--font-regular);
    }

    #onetrust-pc-sdk .ot-vs-config .ot-subgrp-cntr ul.ot-subgrps li.ot-subgrp h5 {
      font-size: var(--font-14);
      height: auto;
      line-height: var(--line-130);
      float: none;
      display: inline-block;
      margin-bottom: var(--spacing-12);
    }

    #onetrust-pc-sdk .ot-accordion-layout .ot-vlst-cntr,
    #onetrust-pc-sdk .ot-accordion-layout .ot-hlst-cntr {
      padding-left: var(--spacing-16);
      padding-bottom: 0;
    }

    // Footer
    #onetrust-pc-sdk .ot-pc-footer {
      position: relative;
      max-height: none;
      min-height: auto;
      background-color: var(--color-light);
      border: none;
      padding-top: var(--spacing-24);
      padding-bottom: var(--spacing-40);
    }

    #onetrust-pc-sdk .ot-btn-container {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: center;
      gap: var(--spacing-16);
    }

    // ---------------------------
    // Vendor list (screen 3)

    #onetrust-pc-sdk #ot-pc-hdr {
      margin: 0;
      box-sizing: border-box;
      position: relative;
      width: auto !important;
    }

    #onetrust-pc-sdk #ot-pc-lst {
      position: relative;
      width: auto;
      height: auto;
      max-height: 100%;
      overflow-y: auto;
      top: 0;
      bottom: 0;
      padding-left: var(--gutter);
      padding-right: var(--gutter);
      flex-grow: 1;
    }

    #onetrust-pc-sdk #ot-lst-title {
      margin: 0;
      font-size: inherit;
      position: relative;

      &::before {
        display: block;
        content: '';
        background-image: url("../../../../icons/logo.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: var(--spacing-32-step);
        height: var(--spacing-32-step);
        margin-top: var(--spacing-32-step);
        margin-bottom: var(--spacing-16);
      }

      button {

        svg {
          display: none;
        }

        &::before {
          content: "";
          position: absolute;
          left:0;
          top:0;
          bottom:0;
          margin:auto 0;
          width: var(--spacing-20);
          height: var(--spacing-20);
          mask-image: url("../../../../icons/chevron-left.svg");
          mask-position: center left;
          mask-size: auto var(--spacing-20);
          mask-repeat: no-repeat;
          background-color:var(--color-dark);
        }

        &::after {
          content: 'Tilbage';
          font-size: var(--font-16);
        }
      }

      h3 {
        display: block;
        margin: var(--spacing-12) 0 0;
        font-size: var(--font-14);
        font-weight: var(--font-regular);
        padding-top: var(--spacing-24);
        padding-bottom: var(--spacing-16);
      }
    }

    #onetrust-pc-sdk .ot-lst-subhdr {
      display: block;
      padding: 0 0 var(--spacing-8);
      position: relative;
      box-sizing: border-box;
    }

    #onetrust-pc-sdk .ot-search-cntr {
      float: none;
      width: auto;
    }

    #onetrust-pc-sdk #vendor-search-handler {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 0 var(--spacing-16) 0 var(--spacing-40);
      height: var(--spacing-40);
      line-height: var(--spacing-40);
      border-radius: 0;
      float: none;

      background-image: url("../../../../icons/search.svg");
      background-size: var(--spacing-16);
      background-repeat: no-repeat;
      background-position: var(--spacing-16) center;
      border: var(--spacing-1) solid var(--color-300);
    }

    #onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) {
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      height: auto;
      max-height: none;
    }

    #onetrust-pc-sdk .ot-acc-cntr {
      border: none;
      background-color: var(--color-100);
      margin-bottom: var(--spacing-8);
    }

    #onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) .ot-acc-cntr>.ot-acc-hdr,
    #onetrust-pc-sdk #ot-pc-lst .ot-acc-hdr {
      background-color: transparent;
      padding: var(--spacing-16);
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    // Chevron for General Vendors
    #onetrust-pc-sdk #ot-pc-lst .ot-host-item {
      button.ot-host-box[aria-expanded="false"] + .ot-acc-hdr::before {
        content: '';
        display: block;
        height: var(--spacing-20);
        width: var(--spacing-20);
        background-image: url("../../../../icons/chevron-down.svg");
        background-position: center;
        background-size: var(--spacing-20);
        background-repeat: no-repeat;
        margin-right: var(--spacing-16);
      }

      button.ot-host-box[aria-expanded="true"] + .ot-acc-hdr::before {
        content: '';
        display: block;
        height: var(--spacing-20);
        width: var(--spacing-20);
        background-image: url("../../../../icons/chevron-up.svg");
        background-position: center;
        background-size: var(--spacing-20);
        background-repeat: no-repeat;
        margin-right: var(--spacing-16);
      }

    }
    #ot-pc-lst #ot-addtl-venlst .ot-acc-hdr::after{display:none!important;}

    #onetrust-pc-sdk .ot-sel-all {
      display: flex;
    }

    #onetrust-pc-sdk .ot-consent-hdr {
      max-width: none;
      white-space:nowrap;
    }

    #onetrust-pc-sdk .ot-vensec-title,
    #onetrust-pc-sdk .ot-ven-hdr {
      flex-grow: 1;
      font-size: var(--font-16);
      font-weight: var(--font-bold);
    }

    #onetrust-pc-sdk .ot-ven-hdr {
      padding-left: var(--spacing-32);
    }

    #onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) .ot-sel-all-chkbox {
      float: none;
      right: 0;
      width: 0;
    }

    #onetrust-pc-sdk .ot-acc-cntr button[aria-expanded=true]~.ot-acc-hdr {
      border-bottom: var(--spacing-4) solid var(--color-100);

      &::after {
        transform: rotate(180deg);
      }
    }

    #onetrust-pc-sdk .ot-acc-cntr>button[aria-expanded=true]~.ot-acc-txt {
      margin-top: 0;
      background-color: var(--color-light);
      overflow: visible;
    }

    #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
      background-color: transparent;
      padding-bottom: var(--spacing-16);
    }

    #onetrust-pc-sdk .ot-acc-cntr>.ot-acc-txt {
      padding: 0;
    }

    #onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) ul li {
      border: none;
      padding: 0;
      background-color: var(--color-100);
      border-bottom: var(--spacing-1) solid var(--color-200);
      position: relative;
      margin: 0;

      &:nth-child(1){
        border-top: var(--spacing-1) solid var(--color-200);
      }
    }

    #onetrust-pc-sdk .ot-ven-name {
      font-size: var(--font-14);
    }

    #onetrust-pc-sdk .ot-ven-link {
      font-size: var(--font-14);
    }

    #onetrust-pc-sdk #ot-lst-cnt:not(.ot-host-cnt) .ot-tgl-cntr {
      position: absolute;
      top: var(--spacing-16);
      left: var(--spacing-16);
      margin: 0;
    }

    #onetrust-pc-sdk .ot-ven-ctgl {
      margin: 0;
    }

    #onetrust-pc-sdk .ot-ven-disc,
    #onetrust-pc-sdk .ot-ven-disc b,
    #onetrust-pc-sdk .ot-ven-dets p,
    #onetrust-pc-sdk .ot-ven-dets h4,
    #onetrust-pc-sdk .ot-ven-dets span {
      font-size: var(--font-14);
    }

    #onetrust-pc-sdk #ot-ven-lst .ot-leg-btn-container {
      width: auto;
      display: block;
      margin: 0 var(--spacing-16) var(--spacing-16);
    }

    #onetrust-button-group-parent::after,
    #ot-pc-content.ot-pc-scrollbar ~.ot-pc-footer::before {
      position: absolute;
      top: calc(var(--spacing-24-step) * -1);
      height: var(--spacing-24-step);
      left: 0;
      content: '';
      right: 0;
      background: linear-gradient(transparent, var(--color-light-800) 50%, var(--color-light));
      pointer-events: none;
      z-index: 3;

    }
  }
}

@include respond-to(small) {
  div#onetrust-consent-sdk {

    #onetrust-banner-sdk,
    #onetrust-pc-sdk {
      top: var(--gutter);
      max-height: calc(100dvh - (var(--gutter) * 2));
    }
  }
}

@include respond-to(medium) {
  div#onetrust-consent-sdk {

    #onetrust-pc-sdk {
      height: auto !important;
    }

    #onetrust-banner-sdk .ot-sdk-row,
    #onetrust-pc-sdk,
    #onetrust-pc-sdk.otPcCenter {
      width: calc(var(--main-medium) + var(--spacing-24));
    }

    #onetrust-banner-sdk #onetrust-button-group-parent {
      border-top: none;
    }

    #onetrust-banner-sdk #onetrust-group-container,
    #onetrust-pc-sdk #ot-pc-content,
    #onetrust-pc-sdk #ot-pc-lst {
      padding-left: var(--spacing-40);
      padding-right: var(--spacing-40);
    }
  }
}

@include respond-to(large) {
  div#onetrust-consent-sdk {

    #onetrust-banner-sdk,
    #onetrust-pc-sdk {
      top: calc(var(--gutter)*4);
      max-height: calc(100dvh - (var(--gutter) * 8));
    }
  }

  @supports not (height:1dvh){
    div#onetrust-consent-sdk {

      #onetrust-banner-sdk,
      #onetrust-pc-sdk {
        max-height: calc(100vh - (var(--gutter) * 8));
      }
    }
  }
}

@include respond-to(print) {
  #onetrust-consent-sdk{display:none!important;}
}
